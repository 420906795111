// @flow strict
import React from 'react';
import { getContactHref } from '../../../utils';
import styles from './Author.module.scss';
import { useSiteMetadata } from '../../../hooks';

const Author = ({cta}) => {
  const { author } = useSiteMetadata();
  function createMarkup() {
    return {__html: cta};
  }
  return (
    <div className={styles['author']}>
      <p className={styles['author__bio']} dangerouslySetInnerHTML={createMarkup()} >
        {/*author.bio*/}
        {/*<a
          className={styles['author__bio-twitter']}
          href={getContactHref('twitter', author.contacts.twitter)}
          rel="noopener noreferrer"
          target="_blank"
        >
          <strong>{author.name}</strong> on Twitter
        </a>*/}
      </p>
    </div>
  );
};

export default Author;
