// @flow strict
import React from 'react';
import { FastCommentsCommentWidget } from 'fastcomments-react';

const Comments = ({postId}) => {
  return (
    <FastCommentsCommentWidget urlId={postId} tenantId="3PZFQUw0Y" />
  );
};

export default Comments;
