// @flow strict
import React from 'react';
import styles from './Meta.module.scss';

type Props = {
  date: string,
  updated: string
};

const Meta = ({ date, updated }: Props) => (
  <div className={styles['meta']}>
    <div className={styles['meta__date']}>
      {
      new Date(date).toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' })
      }
      {
      updated ? <span className={styles['meta__updated']}> &nbsp; Updated {new Date(updated).toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' })}</span> : null 
      }
      
    </div>
  </div>
);

export default Meta;
