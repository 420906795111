// @flow strict
import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import Post from '../components/Post';
import { useSiteMetadata } from '../hooks';
import type { MarkdownRemark } from '../types';
import Page from '../components/Page';
import Sidebar from '../components/Sidebar';
import Footer from '../components/Footer';
type Props = {
  data: {
    markdownRemark: MarkdownRemark
  }
};

const PostTemplate = ({ data }: Props) => {
  const { title: siteTitle, subtitle: siteSubtitle, url: siteTopLevelURL, categoryCtas: categoryCtas } = useSiteMetadata();
  const { frontmatter, fields } = data.markdownRemark;
  const { title: postTitle,
          description: postMetaDescription = '',
          date: date,
          updated: updated,
          socialImage: socialImage = null,
          category: category,
        } = frontmatter;
  const { slug: slug} = fields; //I think this 'fields' version of the slug has been URL encoded including a prepend and trailing slash vs markdownremark's
  
  const cta = categoryCtas[category.replace(' ','_')];

  const socialImageUrl = socialImage ? socialImage.childImageSharp.original.src : '';
  //const {childImageSharp: {original: {src: socialImageUrl}}} = socialImage;

  const metaDescription = postMetaDescription || siteSubtitle;
  const postMetaData = {
    siteTitle: siteTitle,
    siteURL: siteTopLevelURL,
    pageTitle: postTitle,
    pageMetaDescription: metaDescription,
    pageURL: siteTopLevelURL + slug,
    socialImage: siteTopLevelURL + socialImageUrl,
    publishedDate: date,
    updatedDate: updated,
    isBlogPost: true,
  }

  return (
    <Layout>
      <SEO metadata={postMetaData} />
      <Sidebar />
      <Page>
        <Post post={data.markdownRemark} cta={cta} /> {/* data is immutable via const*/}
      <Footer />
      </Page>
    </Layout>
  );
};

export const query = graphql`
  query PostBySlug($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      html
      fields {
        slug
        tagSlugs
      }
      frontmatter {
        date
        updated
        description
        postId        
        tags
        title
        category
        socialImage {
          childImageSharp {
            original {
              src
            }
          }
        }
      }
    }
  }
`;

export default PostTemplate;
